/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "../components/header";
import Footer from "../components/footer";


export default function Layout({ children }) {
return (
        <>
            <Header className="fixed-top bg-white pt-lg-4 wrapper"></Header>
            <main>
                {children}
            </main>
            <Footer />

        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}
