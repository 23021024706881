import {Container, Row, Col} from "react-bootstrap";
import React from "react";
import {Link} from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import setupIconLibrary from "../utils/iconLibrary"
import CTAForm from "../components/ctaForm";
import Logo from "../images/logo.svg";
setupIconLibrary()

const Footer = () => (
    <footer className="footer bg-primary bg-tyre py-5">
        <Container className="col-xl-6 col-lg-8 col-md-8 col-sm-12">
            <Row className="d-flex flex-column">
                <h2 className="footer-title home_title-md text-center font-weight-800 text-white letter-spacing-n2 pt-4 pb-5">Sell Your Car</h2>
                <CTAForm className="justify-content-center"></CTAForm>
            </Row>
        </Container>

        <Container className="mt-6  ">
            <Row>
                <Col md={3} sm={6} className="mb-5 mb-lg-0 mx-auto mx-lg-0">
                    <Link to="/">
                        <img src={Logo} alt="iCashCars" />
                    </Link>
                </Col>
                <Col md={3} sm={6} className="ml-auto text-center text-md-left">
                    <h6 className="h5 font-weight-800 text-white text-uppercase">Quick Links</h6>
                    <ul className="list-unstyled">
                        <li><Link to="/how-it-works" className="text-white">How it works</Link></li>
                        <li><Link to="/why-choose-us" className="text-white">Why Choose Us?</Link></li>
                        <li><Link to="/frequently-asked-questions" className="text-white">FAQ's</Link></li>
                        <li><Link to="/user/leads" className="text-white">Traders</Link></li>
                        <li><Link to="/sell-your-car/sell-your-car" className="text-white">Sell my car</Link></li>
                        <li><Link to="/part-exchange-your-car/part-exchange" className="text-white">Part exchange your car</Link></li>
                        <li><Link to="/buy-your-car/buy-my-car" className="text-white">Buy my car</Link></li>
                        <li><Link to="/any-make-any-model/any-make-any-model" className="text-white">Any Make Any Model</Link></li>
                        <li><Link to="/photo-guide" className="text-white">How to Photo Your Car</Link></li>
                        <li><p></p></li>
                        <li><Link to="/testimonials" className="text-white">Testimonials</Link></li>
                        <li><Link to="/terms-and-conditions" className="text-white">Terms & Conditions</Link></li>
                        <li><Link to="/privacy-policy" className="text-white">Privacy Policy</Link></li>
                    </ul>
                </Col>
                <Col md={3} sm={6} className="text-center text-md-left">
                    <h6 className="h5 font-weight-800 text-white text-uppercase">Registration Numbers</h6>
                    <div className="text-white">
                        <p><span className="footer-reg-title font-weight-bold">Company:</span><span className="footer-reg-content">11063712</span></p>
                        <p><span className="footer-reg-title font-weight-bold">VAT:</span><span className="footer-reg-content">GB 351 2116 45</span></p>
                        <p><span className="footer-reg-title font-weight-bold">FCA:</span><span className="footer-reg-content">914901</span></p>
                    </div>
                </Col>
                <Col md={3} sm={6} className="text-center text-md-left">
                    <h6 className="h5 font-weight-800 text-white text-uppercase">Get in Touch</h6>
                    <ul className="list-unstyled">
                        <li><a className="text-white" href="tel:0800 2884966"><FontAwesomeIcon icon="phone" className="" /> 0800 2884966</a></li>
                        <li><a className="text-white" href="mailto:hello@icashcars.co.uk"><FontAwesomeIcon icon="envelope" className="" /> hello@icashcars.co.uk</a></li>
                        <li><a className="text-white" href="https://www.facebook.com/icashcars.co.uk/"><FontAwesomeIcon icon={['fab', 'facebook']} className="" /> icashcars</a></li>
                        <li><a className="text-white" href="https://www.instagram.com/icashcarsltd/?hl=en"><FontAwesomeIcon icon={['fab', 'instagram']} className="" /> icashcars</a></li>
                    </ul>
                </Col>
            </Row>

        </Container>
    </footer>
)
export default Footer
