import {Button, FormControl, InputGroup} from "react-bootstrap";
import Gb from "../images/gb.png";
import arrowGrey from "../images/icons/arrow-grey.svg";
import React, {Component} from "react";

class CTAForm extends Component {

    handleOnSubmit = e => {
        e.preventDefault();
        let vrm = document.getElementById("vrm").value;
        vrm = vrm.replace(/\W/g, '');
        window.location.href = "https://crm.icashcars.co.uk/value-my-car/" + vrm;
    };

    render(){
        return (
            <form onSubmit={this.handleOnSubmit} className="form-value-from-reg">
                <div className="row no-gutters mb-3">
                    <InputGroup className="mb-3 input-group bg-white faux-input rounded">
                        <InputGroup.Prepend
                            className="bg-blue rounded-left new-form-input-group-text align-items-center text-center">
                            <img src={Gb} className="img-fluid mx-auto" alt=""/>
                        </InputGroup.Prepend>
                        <FormControl
                            id="vrm"
                            placeholder="Enter Reg Here"
                            aria-label="Enter Reg Here"
                            className="faux-input-input form-control m-2 text-center rounded-lg"
                            name="numberPlate"
                        />
                    </InputGroup>
                </div>
                <Button variant="yellow" type="submit" className="cta"> {this?.props?.CTAButtonText !== undefined ? this.props.CTAButtonText + " " : "Sell My Car "} <img src={arrowGrey} alt=""/></Button>
            </form>
        )
    }
}

export default CTAForm
