import {faPhone, faEnvelope, faMapMarker, faChevronCircleRight }  from "@fortawesome/free-solid-svg-icons"
import { fab, faFacebook, faWhatsapp, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { library } from "@fortawesome/fontawesome-svg-core"

export default function setupIconLibrary() {
    library.add(
        fab,
        faFacebook,
        faWhatsapp,
        faMapMarker,
        faEnvelope,
        faPhone,
        faChevronCircleRight,
        faInstagram
    )
}
